import LTT from "@/modules/terror_table/local_terror_table";
import RTT from "@/modules/terror_table/remote_terror_table";
import type {FetchRequestParams as RttFetchRequestParams} from "@/modules/terror_table/remote_terror_table";
import TerrorColumnBuilder from "@/modules/terror_table/terror_column_builder";
import {
  ITableOptions,
  TerrorTableEvent,
} from "@/modules/terror_table/base_terror_table";
import {IColumn} from "@/modules/terror_table/terror_column";

function LocalTerrorTable<Item>({
  options,
  columns,
  data,
  autoInit = true,
}: {
  options: Omit<ITableOptions<Item>, `dataUrl` | `rows`>,
  columns: IColumn<Item>[],
  data: Item[],
  autoInit?: boolean,
}) {
  const table = LTT(options, columns, data);

  if (autoInit) {
    table.initialRender();
  }

  return table;
}

function RemoteTerrorTable<Item>({
  options,
  columns,
  autoInit = true,
}: {
  options: ITableOptions<Item> & {
    rows: number;
    dataUrl: string;
    pageLoadStrategy?: `onScroll` | `onLoadMoreButton`;
    dataRequestParams?: (data: RttFetchRequestParams) => RttFetchRequestParams;
  };
  columns: IColumn<Item>[];
  autoInit?: boolean;
}) {
  const table = RTT(options, columns);

  if (autoInit) {
    table.initialRender();
  }

  return table;
}

export {
  TerrorColumnBuilder,
  LocalTerrorTable,
  RemoteTerrorTable,
  TerrorTableEvent,
};
