import h from 'stringjsx';
import I18n from '@/modules/i18n';
import {IColumn} from './terror_column';
import BaseTerrorTable, {ITableOptions} from './base_terror_table';
import {get} from 'lodash';

export type FetchRequestParams = {
  rows: string; // Should really be number, but TS no likey. Does work sending '100', etc.
  page: string; // Should really be number, but TS no likey. Does work sending '2', etc.
  sidx: string;
  sord: string;
} & Record<string, string>;

export default function RemoteTerrorTable<Item>(
  tableOptions: ITableOptions<Item> & {
    rows: number;
    dataUrl: string;
    pageLoadStrategy?: `onScroll` | `onLoadMoreButton`;
    dataRequestParams?: (data: FetchRequestParams) => FetchRequestParams;
  },
  tableColumns: IColumn<Item>[],
) {
  tableOptions.filterMethod = `onEnter`;

  tableOptions.pageLoadStrategy = tableOptions.pageLoadStrategy || `onScroll`;
  if ((tableOptions.pageLoadStrategy !== `onScroll`) && (tableOptions.pageLoadStrategy !== `onLoadMoreButton`)) {
    throw `pageLoadStrategy must be onScroll or onLoadMoreButton, got ${tableOptions.pageLoadStrategy}`;
  }

  if (tableOptions.pageLoadStrategy == `onLoadMoreButton`) {
    tableOptions.footer = () => (
      <tr>
        <td colspan={1000} js-load-more-holder class="load-more-holder"></td>
      </tr>
    );
  }

  tableOptions.dataRequestParams ||= data => data;

  const baseTerrorTable = BaseTerrorTable(tableOptions, tableColumns);

  const REFETCH_ABORT_MSG = `abort caused by refetch`;
  let lastRowsCount: number | undefined = undefined,
    ongoingRequest: JQuery.jqXHR | undefined = undefined;

  function initialRender(): void {
    baseTerrorTable.initialRender();
    fetch();
    baseTerrorTable.htmlRef().on(`sortChanged filtersChanged`, filterAndSort);
    baseTerrorTable.htmlRef().on(`pageChanged`, pageChanged);
    if (!tableOptions.pagination?.enabled) {
      if (tableOptions.pageLoadStrategy === `onLoadMoreButton`) {
        updateLoadMoreButton();
      } else if (tableOptions.pageLoadStrategy === `onScroll`) {
        $(document).off(`scroll.infinite`);
        $(document).on(`scroll.infinite`, infiniteScroll);
      }
    }
  }

  function updateLoadMoreButton() {
    if (isScrollEndReached()) {
      $(document)
        .find(`[js-load-more-holder]`)
        .html(``);
    } else {
      $(document)
        .find(`[js-load-more-holder]`)
        .html(
          <div js-load-more class="flex load-more">
            <div class="line-container">
              <div class="horizontal-line"></div>
            </div>
            <div>{I18n.t(`load_more`)}</div>
            <div class="line-container">
              <div class="horizontal-line"></div>
            </div>
          </div>
        );

      $(document)
        .find(`[js-load-more-holder] [js-load-more]`)
        .on(`click`, fetchMore);
    }
  }

  function isScrollEndReached(): boolean {
    if (lastRowsCount === undefined) return false;
    return lastRowsCount !== tableOptions.rows;
  }

  function infiniteScroll(): void {
    if ($(window).scrollTop()! >= $(document).height()! - $(window).height()! - 700) {
      fetchMore();
    }
  }

  function filterAndSort(): void {
    baseTerrorTable.setPage(1);
    fetch();
  }

  function pageChanged(): void {
    fetch();
  }

  function isLoading(): boolean {
    if (ongoingRequest === undefined) return false;

    // 0   UNSENT  Client has been created. open() not called yet.
    // 1   OPENED  open() has been called.
    // 2   HEADERS_RECEIVED  send() has been called, and headers and status are available.
    // 3   LOADING   Downloading; responseText holds partial data.
    // 4   DONE  The operation is complete.
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
    return [1, 2, 3].includes(ongoingRequest.readyState);
  }

  function fetchMore() {
    if (isLoading() || isScrollEndReached()) return;
    baseTerrorTable.setPage(baseTerrorTable.getPage() + 1);
    fetch(true);
  }

  function fetch(appendRows = false): void {
    if (isLoading()) ongoingRequest!.abort(REFETCH_ABORT_MSG);

    const data = tableOptions.dataRequestParams!({
      rows: String(tableOptions.rows),
      page: String(baseTerrorTable.getPage() || 1),
      sidx: baseTerrorTable.sort.sidx.toLowerCase(),
      sord: baseTerrorTable.sort.sord.toLowerCase(),
      ...baseTerrorTable.filters,
    } as FetchRequestParams);

    ongoingRequest = $.ajax({
      url: tableOptions.dataUrl,
      type: `GET`,
      data,
      success: (response: ([] | { data: Item[], total: number })) => {
        let rows: Item[] = [];
        if (Array.isArray(response)) {
          rows = response;
        }

        if (tableOptions.pagination?.enabled) {
          const totalCount = get(response, tableOptions.pagination.totalRowsPath);
          rows = get(response, tableOptions.pagination.dataGetterPath) || [];
          baseTerrorTable.setNumberOfPages(Math.ceil(totalCount / (tableOptions.rows || 100)) || 1);
        }

        lastRowsCount = rows.length;

        if (appendRows) {
          baseTerrorTable.dataAPI.appendRows(rows);
        } else {
          baseTerrorTable.dataAPI.setRows(rows);
        }
        baseTerrorTable.reRenderBody();

        if (tableOptions.pageLoadStrategy === `onLoadMoreButton`) {
          updateLoadMoreButton();
        }
      },
      beforeSend: () => {
        baseTerrorTable.loaderRef().removeClass(`invisible`);
      },
      complete: (xhr, statusText) => {
        if (String(statusText) == REFETCH_ABORT_MSG) return;
        baseTerrorTable.loaderRef().addClass(`invisible`);
      },
    });
  }

  function refresh(): void {
    baseTerrorTable.setPage(1);
    filterAndSort();
  }

  return {
    htmlRef: baseTerrorTable.htmlRef,
    initialRender,
    refresh,
    selectRows: baseTerrorTable.selectRows,
    deselectRows: baseTerrorTable.deselectRows,
  };
}
